<template>
	<v-container>
		<v-form v-model="valid" ref="form" v-on:submit.prevent="submitForm" id="this_form">
		<div class="text-h2" style="margin:20px 0">Legg til oppskrift</div>
		<v-row>

			<v-col sm="4" cols="6">
				<v-combobox
					v-model="title"
					name="title"
					:rules="titleRules"
					label="Tittel"
					cache-items
					:items="recipe_titles"
					:search-input.sync="searchRecipes"
					required
				></v-combobox>
			</v-col>
			<v-col sm="4" cols="6">
				<v-text-field
				v-model="mood"
				name="mood"
				:rules="moodRules"
				:counter="10"
				label="Stemning"
				required
				></v-text-field>
			</v-col>
			<v-col style="margin:18px 0 0 0" cols="12" sm="4">
				<v-slider
					label="Vanskelighetsgrad"
					v-model="difficulty"
					name="difficulty"
					thumb-label="always"
					max="10"
					min="1"
				></v-slider>
			</v-col>
		</v-row>
		<v-file-input
			counter
			max-width="200"
			ref="image"
			name="image"
			@change="handleFileUpload"
			show-size
			truncate-length="25"
		></v-file-input>
		<v-divider style="margin:20px 5px"></v-divider>
		<v-row no-gutters>
			<v-col sm="7" cols="12">
				<div class="text-h4 mb-5">Framgangsmåte</div>
				<div v-for="(textField, index) in steps" v-bind:key="title+index">
					<v-row>
						<v-col cols="1" class="text-center">
							<div class="text-h2">{{index}}</div>
							<v-btn small fab dark color="error" depressed @click="deleteStep(index)"><v-icon dark>
							mdi-minus
							</v-icon></v-btn>
						</v-col>
					<v-col>
					<v-text-field
						label="Tidsbruk"
						v-model="textField.time"
					></v-text-field>
					<v-textarea
						outlined
						v-model="textField.value"
						name="step"
						label="Beskrivelse"
					></v-textarea>
					</v-col>
					</v-row>
				</div>
				<div style="float:right;">
					<v-btn @click="addStep" color="indigo" dark>
						<v-icon dark>
							mdi-plus
						</v-icon>Nytt steg
					</v-btn>
				</div>
			</v-col>
			<v-spacer></v-spacer>
			<v-divider
			vertical
			class="d-none d-md-flex"
			></v-divider>
			<v-spacer></v-spacer>
			<v-col cols="12" sm="4">
				<div class="text-h4 mb-4">Ingredienser</div>
				<v-sheet v-for="(ingredient, index) in selectedIngredients" v-bind:key="index+ingredient.product" elevation="1" class="py-4 px-3 my-6">
				<v-row align="center" justify="center">
					<v-col cols="2" class="py-0">
					<v-btn small fab dark color="error" depressed @click="deleteIngredient(index)"><v-icon dark>
							mdi-minus
							</v-icon></v-btn>
					</v-col>
					<v-col cols="10" class="py-0">
						
						<v-subheader>{{ingredient.product}}<br>
						</v-subheader>
						

					</v-col>

					<v-col cols="12" class="py-0" v-if="ingredient.keywords.length > 0">
						<v-chip-group active-class="primary--text" v-model="ingredient.selectedKeywords" multiple><v-chip v-for="(keyword) in ingredient.keywords" v-bind:key="keyword+ingredient.product">
							{{keyword}}
						</v-chip></v-chip-group>
					</v-col>
					<v-col cols="12" class="pt-6 pb-0">
						<v-row>
							<v-col cols="6" class="py-0">
							<v-text-field
								name="ingredient_amount[]"
								v-model="ingredient.value"
								label="mengde"
								:value="ingredient.value"
							></v-text-field>
							</v-col>
							<v-col cols="6">
							<v-select
								v-model="ingredient.measurement"
								:items="[...ingredient.portions, ...default_measurement]"
								:label="ingredient.measurement"
								solo
								dense
								></v-select>
							</v-col>
							</v-row>
					</v-col>
					
				</v-row>
				</v-sheet>
				<v-autocomplete
					solo
					v-model="ingredientValues"
					:items="items"
					:search-input.sync="searchIngredients"
					hide-selected
					multiple
					chips
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-btn @click="clearForm()" color="yellow" dark class="my-8">
			Reset
		</v-btn>
		<v-btn @click="deleteRecipe()" color="error" class="ma-8">Slett Oppskrift</v-btn>
		<v-btn @click="deleteImage()" color="error" class="my-8">Slett Bilde</v-btn>
		<v-btn type="submit" block color="green" dark class="my-8">
			Lagre
		</v-btn>
		</v-form>
		<v-snackbar
			outlined
			v-model="snackbar"
			:color="snackbar_color">
			{{this.snackbar_text}}
			<v-btn
				:color="snackbar_color"
				text
				@click="snackbar = false"
			>Lukk</v-btn>
		</v-snackbar>
	</v-container>

</template>
<script type="text/javascript">
	import axios from 'axios';
	var DB = false;
	const DB_NAME = 'app_db';
	const DB_VERSION = 1; 

	var dbClass = async function() {
	return new Promise((resolve, reject) => {

		if(DB) { return resolve(DB); }
		console.log('OPENING DB', DB);
		let request = window.indexedDB.open(DB_NAME, DB_VERSION);
	
		request.onerror = e => {
			console.log('Error opening db', e);
			reject('Error');
		};

		request.onsuccess = e => {
			DB = e.target.result;
			console.log("Resolving");
			resolve(DB);
		};
	

		request.onupgradeneeded = (event) => {
			console.log("Upgrading DB");
			// Save the IDBDatabase interface
			var dab = event.target.result;
			// Create an objectStore for this database
			var queryStore = dab.createObjectStore("queries", { keyPath: "searchValue" });
			var ingredientStore = dab.createObjectStore("ingredients", { keyPath:"ingredientName"});
			ingredientStore.transaction.oncomplete = function() {
				console.log("Completed!");
				// Store values in the newly created objectStore.
				//var customerObjectStore = dab.transaction(["ingredients"], "readwrite").objectStore("ingredients");
			};
			queryStore.transaction.oncomplete = function(){
				console.log("Got query store");
			}
		};
	});
};
var db = dbClass();
	export default {
		metaInfo: {
			// override the parent template and just use the above title only
			title: 'Legg til oppskrift',
			// all titles will be injected into this template
			titleTemplate: '%s | Brukmat'
		},
		data: () => ({
			valid: false,
			title: '',
			snackbar_text:'',
			snackbar:false, 
			ingredientValues: [],
			selectedIngredients: [],
			searchIngredients: '',
			searchRecipes: '',
			recipe_titles: [],
			default_measurement: [{text:"gram", value:[1, "gram"]},{text:"hg", value:[100, "hg"]},{text:"kg", value:[1000, "kg"]}],
			snackbar_color: 'deep-purple accent-4',
			mood: '',
			image:'',
			progress:0,
			steps: [{value:'', time:''}],
			items: [],
			difficulty: 5,
			titleRules: [
				v => !!v || "Tittel er påkrevd",
				v => v.length >= 5 || "Tittel må være lengre en 5 bokstaver"
			],
			moodRules: [
				v => !!v || "Stemning er påkrevd",
				v => v.length <= 10 || "Stemning må være kortere en 10 bokstaver"
			],
			deboundIngredients:debounce(function(val){
				this.loading = true;
				/*if(connectedToLocalDb === true){
				if(localStorage.getItem("_query="+val) !== null){
				  this.loading = false;
				  this.items = JSON.parse(localStorage.getItem("_query="+val));
				  return;
				}
			}*/
			
			axios.get('https://api.brukmat.no/ingredients/search/?ingredient='+val).then((res)=>{
				this.loading = false;

				let ingredients = res.data.map((item)=>{
					let keywords = item.keywords.join(", ");
					if(!keywords) return {product:item.product, raw:item};
					return {product: item.product + " - "+keywords, raw:item};
				});
				let products = ingredients.map((item)=>{
					return item.product;
				});
				
				this.items = products;
				
				db.then((db_obj)=>{
				var objectStore = db_obj.transaction(["ingredients"], "readwrite").objectStore("ingredients");
				for(let ingredient of ingredients){
					let data = {
					ingredientName: ingredient.product,
					...ingredient.raw
					}
					console.log(data);
					objectStore.put(data);
				}
				});
				db.then((db_obj)=>{
					var objectStore = db_obj.transaction(["queries"], "readwrite").objectStore("queries");
					let data = {
						searchValue: val,
						properties: products
					}
					objectStore.put(data);
					/*for(let product of products){
					let data = {
					  searchValue:product,
					  property:"Example property"
					}
					objectStore.put(data);
					console.log("Data stored");
					}*/
				})

				
				}).catch((err)=>{
					this.snackbar = true;
					this.snackbar_text = "En nettverksfeil oppstod";
					console.log(err);
				});
				console.log(val);
			}, 250),
			deboundRecipes:debounce(function(val){
				console.log(val);
				axios.get('https://api.brukmat.no/recipe/search/?recipe='+val).then((res)=>{
					console.log("Response is",res);
					if(res === ''){
						return false;
					}
					let items = res.data.map(item => item.properties.title);
					this.recipe_titles = items;
				});
			}, 250)
		}),
		methods: {
			addStep: function(){
				this.steps.push({value:'', time:''});
			},
			deleteStep: function(stepIndex){
				let userAction = confirm("Er du sikker på at du vil slette dette steget?");
				if(userAction){
					this.steps.splice(stepIndex, 1);
				}
			},
			handleFileUpload: function(file){
				this.progress = 0;
				this.image = file;
			},
			showError: function(text){
				this.snackbar_text = text;
				this.snackbar_color = "error";
				this.snackbar = true;
			},
			deleteRecipe: function(){

				let userAction = confirm("Er du sikker på at du vil slette denne oppskriften?");
				if(userAction){
					var localStorage = window.localStorage;
					let apiKey = localStorage.getItem("api_key");
					axios.delete('https://api.brukmat.no/recipe/?recipe='+this.title, 
						{headers: {'api-key': apiKey, 'auth-bearer': this.$store.getters.idToken}}).then((res)=>{
							console.log(res);
							this.clearForm();
					}).catch(err=>{
						switch(err.response.status){
							case 404:
								// If couldn't find recipe
								this.showError("Oppskriften kan ikke slettes siden den ikke eksisterer");
							break;
							default:
								this.showError("En nettverksfeil oppstod... Feilkode:"+err.response.status);
								// Other errors
							break;
						}
						console.log(err.response.status);
					});
				}
			},
			deleteImage: function(){
				var localStorage = window.localStorage;
				let apiKey = localStorage.getItem("api_key");
				axios.delete('https://api.brukmat.no/recipe/image?recipe='+this.title, {
					headers: {"api-key": apiKey, 'auth-bearer': this.$store.getters.idToken}
				}).then(data=>{
					console.log("Deleted image?",data);
				});
			},
			deleteIngredient: function(ingredientIndex){
				this.selectedIngredients.splice(ingredientIndex, 1);
			},
			clearForm: function(){
				this.selectedIngredients = [];
				this.steps = [{value:'',time:''}];
				this.title = '';
				this.difficulty = 5;
				this.mood = '';
				//this.$refs.form.reset();
			},
			submitForm: function(){
				this.$refs.form.validate();
				if(this.valid){
					var localStorage = window.localStorage;
					let apiKey = localStorage.getItem("api_key");
					console.log(apiKey);
					// Create or change the recipe here... Wait for response.
					console.log(this.title, this.mood, this.difficulty);
					axios.post('https://api.brukmat.no/create/recipe', {
						title:this.title,
						mood:this.mood,
						difficulty:this.difficulty,
						img_url:this.title+'.webp'
					}, {headers: {'Content-Type': 'application/json', 'api-key': apiKey, 'auth-bearer': this.$store.getters.idToken}}).then((res)=>{
						console.log(res);

						if(res.data.status == 1){
							console.log(this.image);
							let formData = new FormData();
							formData.append('recipe', this.title);
							formData.append("poster", this.image);

							axios.post("https://api.brukmat.no/recipe/image/", formData, {
								headers: {
									"Content-Type": "multipart/form-data",
									"api-key": apiKey,
									'auth-bearer': this.$store.getters.idToken
								}
							});
							let steps = this.steps.map(step=>step.value);
							// Assign steps to recipe
							axios.post('https://api.brukmat.no/recipe/add-steps/',
							{
								"title":this.title,
								"steps":steps
							}, {headers: {'Content-Type': 'application/json', 'api-key': apiKey, 'auth-bearer': this.$store.getters.idToken}});
							let ingredientsToInsert = this.selectedIngredients.map(ing=>{
								let keywords = [];
								if(ing.selectedKeywords && ing.selectedKeywords.length !== 0){
									for(let key of ing.selectedKeywords){
										keywords.push(ing.keywords[key]);
									}
								}
								return {
									"product":ing.product,
									"amount":ing.value,
									"amount_in_gram":ing.value*ing.measurement[0],
									"measurement":ing.measurement[1],
									"keywords":keywords
								}
							});
							axios.post('https://api.brukmat.no/create/relationship/ingredients-to-recipe', {
								"recipe": this.title,
								"ingredients": ingredientsToInsert
							}, {headers: {'Content-Type': 'application/json', 'api-key': apiKey, 'auth-bearer': this.$store.getters.idToken}});
							console.log("ingredientsToInsert", ingredientsToInsert);
							console.log("Ingredients", this.selectedIngredients);
						} else {
							// Throw error or something here
							console.log("Unable to execute...");
						}
						

					}).catch(err=>{
						console.log("Got error", err);
						console.log(err.response.status);
						switch(err.response.status){
							case 401:
								this.snackbar_text = "Mangler/Feil API nøkkel";
								this.snackbar = true;
							break;
							default:
								this.snackbar_text = "En nettverksfeil oppstod... Feilkode:"+err.response.status;
								this.snackbar = true;
						}
						//alert(err);
					});
				}
				
				/*let myForm = document.getElementById('this_form');
				let formData = new FormData(myForm);
				for(var pair of formData.entries()) {
				   console.log(pair[0]+ ', '+ pair[1]);
				}*/
				//console.log(formData)
			}
		},
		watch: {
			title(val){

				console.log("Title watch", val);
				axios.get('https://api.brukmat.no/recipe/?recipe='+val).then((res)=>{
					console.log("Recipe response is",res);
					//let items = res.data.map(item => item.properties.title);
					//this.recipe_titles = items;
					if(res.data.properties.title){
						console.log("Hello...");
						this.mood = res.data.properties.mood;
						this.difficulty = res.data.properties.difficulty;
						axios.get('https://api.brukmat.no/recipe/get-steps/?title='+val).then((resSteps)=>{
							console.log("Recipe steps is ", resSteps.data);
							let properties = resSteps.data.map(step => {
								return {"value":step.properties.text};
							}
								);
							console.log("Props", properties);
							this.steps = properties;
						});
						axios.get('https://api.brukmat.no/recipe/ingredients/?title='+val).then((resIngredients)=>{
							
							//console.log(resIngredients.data);
							let ingredients = resIngredients.data.map((ing)=>{
								return {
									"amount":ing[0].properties, 
									"ingredient":ing[1].properties,
									"default_ingredient":ing[2].properties};
							});
							let formattedIng = ingredients.map((ing)=> {
									return {
										"product": ing.ingredient.title, 
										"value":ing.amount.amount, 
										"measurement":ing.amount.measurement, 
										"keywords": ing.ingredient.keywords, 
										"selectedKeywords": createArrayWithIndex(ing.ingredient.keywords),
										"portions":portionStringToArray(ing.default_ingredient)
								}
							});
							this.selectedIngredients = formattedIng;
							// Requires ing.ingredientName, ing.value, ing.measurement
							console.log("Ingredients",ingredients);
							console.log("Formatted",formattedIng);
						})

					} else {
						// Error
						console.log("Couldn't get data...");
					}
				});
			},
			searchIngredients(val){
				console.log("Registered press...");
				// Check if value already exists in DB...
				//this.$emit('changeSearch', val);
				if(!val || val.length < 2) return false;
				val = val.toLowerCase();
				if(db){
					try{
						db.then(db_obj=>{
							var objectStore = db_obj.transaction(["queries"], "readwrite").objectStore("queries");
							var request = objectStore.get(val);
							request.onsuccess = (result)=>{
								let items = result.target.result;
								if(!items) {
									this.deboundIngredients(val);
								} else {
									console.log("Found previous search value")
									this.items = items.properties;
								}
							}
						});
					} catch(err){
						console.log("Got error", err);
						this.deboundIngredients(val);

					}
					
				} else {
					this.deboundIngredients(val);
				}
				
				
			},
			searchRecipes: function(val){
				//console.log(val);
				this.deboundRecipes(val);
			},
			ingredientValues: function(items){
				console.log("Ingredient values", items);
				if(db){
					db.then(db_obj=>{
						let objectStore = db_obj.transaction(["ingredients"], "readwrite").objectStore("ingredients");
						let DBResults = [];
						for(let item of items){
							let promise = new Promise((resolve)=>{
								let request = objectStore.get(item);
								request.onsuccess = (result)=>{
									console.log("Completed");
									resolve(result.target.result);
								};
							});
							DBResults.push(promise);
						}
						Promise.allSettled(DBResults).then((result)=>{
							let values = result.map(item=>item.value);
							// Push if doesn't exist in the array
							for(let value in values){
								
								let exists = this.selectedIngredients.find(ing => ing.ingredientName === values[value].ingredientName);
								console.log("Does exist?", exists);
								if(exists !== undefined){
									// Do nothing
								} else {
									// Add new item
									values[value].portions = portionStringToArray(values[value]);
									values[value].value = '100';
									values[value].measurement = "gram";

									//console.log(values[value]);
									console.log(values);
									this.selectedIngredients.push(values[value]);
								}
							}
							// Remove if is removed
							/*for(let ingredient in this.selectedIngredients){
								/*console.log(ingredient);
								let exists = ingredient.find(ing => ing.ingredientName === values[value].ingredientName);
								if(exists === undefined){
									console.log("Element is removed...");
								}
							}*/

							this.snackbar = true;
							this.snackbar_text = "Vi har lagt til ingrediensen i listen din";
							if(values.length === 0) values = false;
							//this.$store.commit('changeText', values);
							/*for(let item of result){
								//console.log(item.value);
							}*/
						});
					})
				}
			}
		}
	}
	function debounce(func, wait, immediate) {
		var timeout;
		return function() {
			var context = this, args = arguments;
			var later = function() {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	}
	function createArrayWithIndex(array){
		let returnArray = [];
		for(let i in array){
			returnArray.push(parseInt(i));
		}
		return returnArray;
	}
	function portionStringToArray(ing){
		let refArray = ing.nutrients_portion_ref.trim().split(" ");
		let valueArray = ing.nutrients_portion_value.trim().split(" ");
		let nutrient_portions_mapped = [];
		if(refArray[0] !== "") {
			nutrient_portions_mapped = refArray.map((refVal, refIndex) =>{
				return {
					text:refVal,
					value:[valueArray[refIndex], refVal]
				}
			});
		}
		return nutrient_portions_mapped;
	}
</script>